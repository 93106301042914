:root {
  --cfa-blue: #3c7ec8;
  --btn-hover: #6e6968;
}
.Homepage button {
  width: 257px;
  height: 40px;
  font-size: 15px;
  background-color: var(--cfa-blue);
  color: white;
  border: none;
  margin: 5px;
}
.Homepage button:hover {
  background-color: var(--btn-hover);
  transition: 0.7s;
}
.Homepage-link {
  text-decoration: none;
  color: black;
  width: 100%;
  height: 100%;
}
