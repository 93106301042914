.Instr {
  text-align: left;
}
#verify {
  display: none;
}
.Registration header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Registration-content {
  width: 60%;
  margin: 0;
  text-align: center;
  font-size: 20px;
}
.Auth-container {
  margin-top: 25px;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.Phone-Num {
  width: 250px;
  height: 35px;
  margin: none;
  font-size: 15px;
}
#recaptcha-container {
  width: 257px;
  height: 40px;
  font-size: 15px;
  background-color: var(--cfa-blue);
  color: white;
  border: none;
}
#recaptcha-container:hover {
  background-color: var(--btn-hover);
  transition: 0.7s;
}

.VP-Filter-container {
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.VP-Filter-container table {
  text-align: left;
}
.Table-container {
  margin-top: 20px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  overflow-y: visible;
}
thead {
  background-color: var(--cfa-blue);
  color: black;
}
tr {
  background-color: white;
  color: black;
}
/* .tr {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: fit-content;
  place-items: center;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: gray;
} */
th {
  padding-left: 50px;
  padding-right: 50px;
}
td {
  padding-top: 10px;
  padding-bottom: 10px;
}
.Help-btn {
  color: var(--cfa-blue);
  text-decoration: underline;
  background-color: #fff;
  border: none;
  width: 50px;
}
@media (max-width: 525px) {
  th {
    padding: 0;
    width: 130px;
  }
}
