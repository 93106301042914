.Patient-Links {
  width: 100%;
}
.Patient-Links ul {
  text-align: left;
}
.Patient-Links ul li {
  word-wrap: break-word;
}
.Register-Link {
  text-decoration: none;
}
.Share-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Image-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Image-poster {
  width: 70%;
}
.Share-btn-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 100px;
}
.Share-btn-p {
  width: 500px;
  text-align: left;
}
.Btn {
  width: 257px;
  height: 40px;
  font-size: 15px;
  background-color: var(--cfa-blue);
  color: white;
  border: none;
}

.Btn:hover {
  background-color: var(--btn-hover);
  transition: 0.7s;
}
@media (max-width: 1200px) {
  .Share-container {
    flex-direction: column;
  }
  .Image-container {
    width: 100%;
  }
  .Image-poster {
    width: 100%;
  }
  .Share-btn-container {
    margin: 0;
    margin-top: 20px;
    width: fit-content;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .Share-btn-p {
    width: 300px;
  }
}
